jQuery('.search-icon').click(function(e) {
	e.preventDefault()
	jQuery('.search-form-container')
		.toggle('slow')
		.css('z-index', '500')
})

jQuery('#search-filter').click(function() {
	var value = jQuery('.post-type-field')

	if (this.checked) {
		value.val('memorial-wall')
	} else {
		value.val(' ')
	}
})
